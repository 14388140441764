import React from 'react';
import PropTypes from 'prop-types';
import { AppDefsHeaderLinks, AdminDropdown, NavbarLink } from 'cccisd-header';
import IconCart from 'cccisd-icons/cart';
import UserDropdown from './UserDropdown.js';
import ReturnToUserLink from './ReturnToUserLink.js';
import wustlLogo from './wustl.svg';
import style from './style.css';

const wpUrl = window.cccisd.env.wpUrl || 'https://familybasedbehavioraltreatment.wustl.edu';
const Fortress = window.cccisd.fortress;

const wpOnlyLinks = [
    { to: wpUrl, text: 'Home' },
    { to: wpUrl + '/treatment-overview', text: 'Treatment Overview' },
    { to: wpUrl + '/new-training', text: 'Training' },
    { to: wpUrl + '/about-us', text: 'About Us' },
    { to: wpUrl + '/contact', text: 'Contact' },
    { to: wpUrl + '/portals', text: 'Portals' },
    { to: wpUrl + '/cart', text: <IconCart /> },
];

const Header = ({ className, isWordpress }) => {
    const renderResourcesDropdown = () =>
        Fortress.auth() &&
        Fortress.user.acting.role.handle === 'uberadmin' &&
        !Fortress.isSuperUser() && (
            <li className="dropdown">
                <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Admin <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                    <NavbarLink to="/resources/admin" highlight="/resources/admin">
                        Resources
                    </NavbarLink>
                </ul>
            </li>
        );

    const renderCoursesDropdown = () =>
        Fortress.auth() &&
        Fortress.user.acting.role.handle === 'uberadmin' && (
            <li className="dropdown">
                <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Courses <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                    <NavbarLink to="/survey/training" highlight="/d/[^a]{1,}">
                        FBT Training
                    </NavbarLink>
                </ul>
            </li>
        );

    const renderWpOnlyLinks = () => (
        <>
            {wpOnlyLinks.map(link => (
                <NavbarLink key={link.to} to={link.to}>
                    {link.text}
                </NavbarLink>
            ))}
        </>
    );

    const renderLaravelOnlyLinks = () =>
        Fortress.auth() ? (
            <>
                <ReturnToUserLink />
                <AppDefsHeaderLinks />
                {renderCoursesDropdown()}
                <AdminDropdown />
                {renderResourcesDropdown()}
                <UserDropdown allowSwitchRoles />
            </>
        ) : (
            <>
                <NavbarLink to={wpUrl} highlight={wpUrl}>
                    Home
                </NavbarLink>
                <NavbarLink to="/login" highlight="login">
                    Login
                </NavbarLink>
            </>
        );

    return (
        <header className={style.header}>
            <div className={style.logoRow}>
                <div className={className}>
                    <a href={wpUrl}>
                        <img
                            src={wustlLogo}
                            alt="Washington University School of Medicine in St. Louis"
                            className={style.logo}
                        />
                    </a>
                </div>
            </div>
            <div className={style.titleRow}>
                <div className={className}>
                    <div className={style.title}>
                        <h1>
                            {isWordpress
                                ? 'Family-based Behavioral Treatment'
                                : 'Family-based Approach for Healthy Lifestyles (RYSE)'}
                        </h1>
                    </div>
                </div>
            </div>
            <div className={style.linksRow}>
                <nav className="navbar navbar-default">
                    <div className={className}>
                        <div className="navbar-header">
                            <button
                                type="button"
                                className="navbar-toggle collapsed"
                                data-toggle="collapse"
                                data-target="#header-navbar-collapse"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                        </div>

                        <div className="collapse navbar-collapse" id="header-navbar-collapse">
                            <ul className="nav navbar-nav navbar-left">
                                {isWordpress ? renderWpOnlyLinks() : renderLaravelOnlyLinks()}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

Header.defaultProps = {
    className: 'container',
    isWordpress: false,
};

Header.propTypes = {
    className: PropTypes.string,
    isWordpress: PropTypes.bool,
};

export default Header;
