import React from 'react';
import { Formik, Form, Field } from 'cccisd-formik';
// import Loader from 'cccisd-loader';
// Tried to add loading state but react hooks cannot be called within the type of function custom renders are
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import CccisdToggle from 'cccisd-toggle';
import style from './style.css';
const Boilerplate = window.cccisd.boilerplate;

const SupervisionToggle = settings => {
    const toggleChange = async (value, pawnId, pawnHash, loadData) => {
        // for some reason its reversed
        const bool = value === 'false' ? true : false;

        try {
            const response = await axios.put(
                Boilerplate.route('api.nexus.pawn.update', {
                    pawn: pawnId,
                }),
                {
                    pawnHash,
                    isSupervisionComplete: bool,
                }
            );

            if (response?.data?.status === 'success') {
                loadData();
                notification({ type: 'success', message: 'Supervision status updated!' });
            } else {
                loadData();
                notification({ type: 'danger', message: 'Failed to update supervision status.' });
            }
        } catch (e) {
            console.error(e);
        }
    };

    return ({ value, row, loadData }) => {
        return (
            <Formik
                onSubmit={() => {}}
                initialValues={{ isSupervisionComplete: value === 'Yes' ? true : false }}
                render={() => (
                    <Form>
                        <Field name="isSupervisionComplete">
                            {props => {
                                return (
                                    <div className={style.wrapper}>
                                        <CccisdToggle
                                            name="isSupervisionComplete"
                                            value={props.field.value}
                                            onChange={e => {
                                                toggleChange(
                                                    e.target.value,
                                                    row['pawn.pawnId'],
                                                    row['pawn.pawnHash'],
                                                    loadData
                                                );
                                                props.field.onChange(e);
                                            }}
                                        />
                                    </div>
                                );
                            }}
                        </Field>
                    </Form>
                )}
            />
        );
    };
};

export default SupervisionToggle;
